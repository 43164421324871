import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import ModalImage from "react-modal-image";

const Gallery = () => {
  return (
    <div>
      <Header />
      <br/><br/>
      <img src="images/gallery/gallery29.jpeg" alt="" className="youthImg mt-5" />
      <br />
      <div className="container">
       <h1 className="text-center text-danger py-4" style={{fontWeight:'550'}}>Gallery</h1>
        <div className="row">
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery01.jpeg"}
                large={"images/gallery/gallery01.jpeg"}
                className=""
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-up">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery02.jpeg"}
                large={"images/gallery/gallery02.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-down">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery22.jpeg"}
                large={"images/gallery/gallery22.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery04.jpeg"}
                large={"images/gallery/gallery04.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery05.jpeg"}
                large={"images/gallery/gallery05.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-down">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery06.jpeg"}
                large={"images/gallery/gallery06.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>

          <div className="col-sm-3" data-aos="fade-up">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery10.jpeg"}
                large={"images/gallery/gallery10.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery11.jpeg"}
                large={"images/gallery/gallery11.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
        </div>{" "}
      </div>{" "}
      <br />
      {/* kasol images */}
      <div className="container">
        {/* <h2 data-aos="fade-up-right">Kasol</h2> */}
        <div className="row">
          <div className="col-sm-3" data-aos="fade-up">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery09.jpeg"}
                large={"images/gallery/gallery09.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery07.jpeg"}
                large={"images/gallery/gallery07.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-down">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery08.jpeg"}
                large={"images/gallery/gallery08.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
              
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery12.jpeg"}
                large={"images/gallery/gallery12.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          
          <div className="col-sm-3" data-aos="fade-down">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery17.jpeg"}
                large={"images/gallery/gallery17.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-right">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery24.jpeg"}
                large={"images/gallery/gallery24.jpeg"}
                className="img-thumbnail"
              />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-up">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery18.jpeg"}
                large={"images/gallery/gallery18.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              /> <br />
            </div>
          </div>
          <div className="col-sm-3" data-aos="fade-up">
            <div className="gallery">
              <ModalImage
                small={"images/gallery/gallery26.jpeg"}
                large={"images/gallery/gallery26.jpeg"}
                alt="udgam gallery"
                className="img-thumbnail"
              /> <br />
            </div>
          </div>
          <br />
        </div>
        <div className="container">
         
          <div className="row">
            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery14.jpeg"}
                  large={"images/gallery/gallery14.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-down">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery26.jpeg"}
                  large={"images/gallery/gallery26.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-up">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery34.jpeg"}
                  large={"images/gallery/gallery34.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery28.jpeg"}
                  large={"images/gallery/gallery28.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery29.jpeg"}
                  large={"images/gallery/gallery29.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-up">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery23.jpeg"}
                  large={"images/gallery/gallery23.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-down">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery31.jpeg"}
                  large={"images/gallery/gallery31.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery30.jpeg"}
                  large={"images/gallery/gallery30.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery33.jpeg"}
                  large={"images/gallery/gallery33.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-down">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery35.jpeg"}
                  large={"images/gallery/gallery35.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
            <div className="col-sm-3" data-aos="fade-up">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery27.jpeg"}
                  large={"images/gallery/gallery27.jpeg"}
                  className="img-thumbnail"
                />
              </div>
            </div>

            <div className="col-sm-3" data-aos="fade-right">
              <div className="gallery">
                <ModalImage
                  small={"images/gallery/gallery36.jpeg"}
                  large={"images/gallery/gallery36.jpeg"}
                  alt="udgam gallery"
                  className="img-thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
      <Footer />
    </div>
  );
};

export default Gallery;