import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const Health = () => {
  return (
    <div>
      <Header /><br/><br/>
      <img src="https://i.pinimg.com/originals/10/e1/8f/10e18f6c9fd3a91875ccdd0094f4fe30.jpg" alt="" className="youthImg mt-5" />

      <div>
        <div className="container pb-30">
          <div className="container pb-30">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 pb-sm-20">
                  <h3 className="line-bottom mt-0">Health</h3>
                  <hr
                    style={{
                      marginTop: "-30px",
                      width: "4%",
                      float: "left",
                      border: "1.2px solid #bd1c2c",
                    }}
                  />
                  <p
                    style={{
                      color: "#333",
                      fontStyle: "bold",
                      fontWeight: 600,
                      fontSize: "18px",
                      textAlign: "justify",
                    }}
                  >
                    <q>
                      <em>
                        He who has health has hope, and he who has hope has
                        everything
                      </em>
                    </q>
                    <br />
                  </p>
                  <p
                    style={{
                      color: "#333",
                      fontStyle: "bold",
                      fontSize: "18px",
                    }}
                  >
                    The Indian healthcare sector has evolved tremendously since
                    independence but access to quality healthcare is still a
                    dream for many. Health statistics of rural poor and urban
                    slum dwellers is abysmal. One of the main reasons behind it
                    is the lack of awareness about the availability of basic
                    health facilities. The health of the underprivileged is thus
                    an important aspect that needs attention and redressal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Health;
