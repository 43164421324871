import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const Donation = () => {
  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <div className="text-center mt-5">
        <div>
          <span style={{ fontSize: "25px", fontWeight: "bold" }}>UPI: </span>{" "}
          <span style={{ fontSize: "20px",color:'gray' }}>9625077715M@pnb</span>
        </div>
        <div>
          <span style={{ fontSize: "25px", fontWeight: "bold" }}>
            Bank Details:{" "}
          </span>{" "}
          <span style={{ fontSize: "20px",color:'gray' }}>
            UDGAM DEVELOPMENT FOUNDATION <br />
            Bank: PNB Branch: Jawala Heri <br />
            Saving Bank Account No: 6576000100046515 <br />
            IFSC code: PUNB0657600
          </span>
        </div>
      </div>
      <div class="container">
        <div class="text-center">
          <h2 className="mt-2 mb-2">Donation Grid</h2>
        </div>
        <table class="table table-bordered" style={{color:'gray'}}>
          <tr style={{color:'gray'}}>
            <td rowspan="2">
              <b>30 day ration for</b>
            </td>
            <td>1</td>
            <td>Family</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Families</td>
            <td>5000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>Safe Periods for</b>
            </td>
            <td>20</td>
            <td>Women</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>100</td>
            <td>Women</td>
            <td>5000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>COVID medical Equipment's for</b>
            </td>
            <td>1</td>
            <td>Village</td>
            <td>10000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Villages</td>
            <td>50000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>Yearly Tution Fees of</b>
            </td>
            <td>1</td>
            <td>Child</td>
            <td>12000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Children</td>
            <td>60000</td>
          </tr>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default Donation;
