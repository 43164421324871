import React from "react";
import Carousel from "../Components/CarouselBanner/Carousel";
import Header from "../Components/Header/Header";
import "../App.css";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <Carousel />
      {/* start about section text */}
      <br />
      <div className="container">
        <h1 style={{ fontWeight: "550", color: "maroon" }}>
          Even if we can change one life, our life will be well lived.
        </h1>
        <p p style={{ color: "gray", lineHeight: "1.6" }}>
          <b>Udgam </b>Development Foundation is the brain child of a group of
          Kendriya Vidyalaya Kankarbagh Patna alumni who have attained academic
          and professional excellence in their respective fields. This team
          firmly believes that now is the time to give back to the society. They
          want to pursue this dream by supporting, inspiring and creating an
          equal and sustainable society by empowering through education,
          healthcare and ecological conservation. As a gratitude to our Alma
          Mater, our giving back initiatives are majorly centered on Kendriya
          Vidyalaya ecosystem - The Students, Teachers, Alumni and Their Family.{" "}
        </p>
      </div>
      <div className="bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div
                className="grid"
                style={{ backgroundColor: "lightblue", borderRadius: "25px" }}
              >
                <div className="pic">
                  <img
                    src="images/gallery/dryration.jpeg"
                    className="img-fluid"
                    alt="udgamImage"
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Feed the poor and jobless
                  </h5>
                  <p
                    className="para"
                    style={{
                      backgroundColor: "SlateBlue",
                      borderRadius: "20px",
                      padding: "20px",
                      color: "white",
                    }}
                  >
                    COVID has taken away livelihood of many and Families are not
                    able to get proper food. Help us feed needy families
                  </p>
                  <p className="link">
                    <a href="/">Donate Now</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className="grid"
                style={{ backgroundColor: "lightblue", borderRadius: "25px" }}
              >
                <div className="pic">
                  <img
                    alt="udgamImage"
                    src="https://5.imimg.com/data5/MK/RV/GLADMIN-39291111/rural-health-services-500x500.png"
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Menstrual Hygiene for Woman
                  </h5>
                  <p
                    className="para"
                    style={{
                      backgroundColor: "SlateBlue",
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "13px",
                      color: "white",
                    }}
                  >
                    Only 36 percent women wear sanitary pads in India. Give poor
                    women access to safe and hygienic periods
                  </p>
                  <p className="link">
                    <a href="/">Donate Now</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className="grid"
                style={{ backgroundColor: "lightblue", borderRadius: "25px" }}
              >
                <div className="pic">
                  <img
                    src="images/gallery/ruralhealth.jpeg"
                    alt="udgamImage"
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Rural Preventive Healthcare
                  </h5>
                  <p
                    className="para"
                    style={{
                      backgroundColor: "SlateBlue",
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "13px",
                      color: "white",
                    }}
                  >
                    Lack of awareness and healthcare facilities has lead to high
                    number of COVID deaths in rural India. Help us equip them
                    with better facilities
                  </p>
                  <p className="link">
                    <a href="/">Donate Now</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div
                className="grid"
                style={{ backgroundColor: "lightblue", borderRadius: "25px" }}
              >
                <div className="pic">
                  <img
                    src="https://www.unicef.org/sites/default/files/styles/hero_desktop/public/UNI355854.jpg?itok=ahm0SWP7"
                    alt="udgamImage"
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h5 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Educate a Child
                  </h5>
                  <p
                    style={{
                      margin: "9px",
                      marginBottom: "20px",
                      backgroundColor: "SlateBlue",
                      borderRadius: "20px",
                      padding: "20px",
                      fontSize: "13px",
                      color: "white",
                    }}
                  >
                    Many children have lost their parents or parents have lost
                    their livelihood. Help by contributing for their educationx{" "}
                  </p>
                  <p className="link">
                    <a href="/">Donate Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="text-center">
          <h2>Donation Grid</h2>
        </div>
        <table class="table table-bordered">
          <tr>
            <td rowspan="2">
              <b>30 day ration for</b>
            </td>
            <td>1</td>
            <td>Family</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Families</td>
            <td>5000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>Safe Periods for</b>
            </td>
            <td>20</td>
            <td>Women</td>
            <td>1000</td>
          </tr>
          <tr>
            <td>100</td>
            <td>Women</td>
            <td>5000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>COVID medical Equipment's for</b>
            </td>
            <td>1</td>
            <td>Village</td>
            <td>10000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Villages</td>
            <td>50000</td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>Yearly Tution Fees of</b>
            </td>
            <td>1</td>
            <td>Child</td>
            <td>12000</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Children</td>
            <td>60000</td>
          </tr>
        </table>
      </div>

      <div className="container-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
            <div className="social">
                <h2 style={{ color: "#3B5897" }}>Follow us on Twitter</h2>
                <div className="fb_section text-center">
                  <iframe
                  src="https://twitter.com/UdgamDevelopme1?ref_src=twsrc%5Etfw"
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  className="iframsDimension"
                    frameBorder={0}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="social">
                <h2 style={{ color: "#3B5897" }}>Follow us on Facebook</h2>
                <div className="fb_section">
                  <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fudgamdevelopmentfoundation&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    style={{ border: "none", overflow: "hidden" }}
                    scrolling="no"
                    allowFullScreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    className="iframsDimension"
                    frameBorder={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Home;
