import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <img src="images/bg/bg8.jpg" alt="" className="youthImg mt-5" />
      <div className="container pb-30">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 pb-sm-20">
              <h3 className="line-bottom mt-5">Our Mission</h3>
              <hr
                style={{
                  marginTop: "-30px",
                  width: "4%",
                  float: "left",
                  border: "1.2px solid #bd1c2c",
                }}
              />

              <p
                style={{
                  fontFamily: "verdana",
                  color: "#333",
                  textIndent: "39px",
                  fontStyle: "bold",
                  fontSize: "18px",
                }}
              >
                <b>Udgam</b> Development Foundation strives to nurture and
                empower the not so privileged section of the society. It would
                act as means to:-
                <br />- Fulfill the dreams of men, women and children of the
                underprivileged section of the society by facilitating quality
                education, healthcare and talent development.
                <br />- Create awareness amongst the children and adults so that
                they develop a sense of belongingness with self, community and
                nature through long term mentoring and community based
                programs.&nbsp; .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-30">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 pb-sm-20">
              <h3 className="line-bottom mt-5">Our Vision</h3>
              <hr
                style={{
                  marginTop: "-30px",
                  width: "4%",
                  float: "left",
                  border: "1.2px solid #bd1c2c",
                }}
              />

              <p
                style={{
                  fontFamily: "verdana",
                  color: "#333",
                  fontStyle: "bold",
                  fontSize: "18px",
                  textAlign: "justify",
                }}
              >
                {" "}
                Udgam Development Foundation aspires to create an equal and
                sustainable society through quality education and healthcare for
                everyone .
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h2 className="text-center" style={{ fontWeight: "550" }}>
          Our <span className="text-theme">Trustees</span>
        </h2>
        <br />
        <br />
        <div className="section-title text-center"></div>
        <div className="section-content">
          <div className="row">
            {/*--start-*/}
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/ajay0.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name text-theme-colored mt-0 mb-0">
                Mr. Ajay Kumar
              </h2>
              <p style={{ color: "green", fontSize: "18px" }}>
                <b>President</b>
              </p>{" "}
              <a href="#demo" data-toggle="collapse">
                More Info
              </a>
              <div id="demo" className="collapse">
                <br />
                <p style={{ textIndent: "50px", fontSize: "17px" }}>
                  {" "}
                  A KVK Patna alumnus, Ajay Kumar is a law graduate,
                  ex-president of the prestigious law faculty of Delhi
                  university and a successful entrepreneur having experience
                  across multiple business domains. With his strong program
                  management skills, in depth knowledge of ground situations and
                  a knack for finding compatible solutions, he is currently
                  managing over 1000 employees with interests in...
                  <br />
                  - Toll management
                  <br />
                  - Security operations
                  <br />
                  - Food, beverage and event management.
                  <br />
                  -Spread over the states of Maharashtra, Gujarat, Madhya
                  Pradesh, Delhi, Rajasthan and Himachal Pradesh.
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/shakti.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name text-theme-colored mt-0 mb-0">
                {" "}
                Shakti Tiwari
              </h2>
              <p style={{ color: "green", fontSize: "18px" }}>
                <b>Vice President</b>
              </p>{" "}
              <a href="#demo3" data-toggle="collapse">
                More Info
              </a>
              <div id="demo3" className="collapse">
                <p style={{ textIndent: "50px", fontSize: "17px" }}>
                  A KVK Patna alumnus, Shakti Tiwari holds a master’s degree in
                  Psychology and has worked in varied fields including Life
                  Insurance, IT, Healthcare and Entrepreneurship spanning
                  through 23 years. A role model and inspiration for people
                  around her, she believes that there is nothing called failure
                  but a steppingstone to further endeavours. An innovator and a
                  relentless optimist she is Currently working with Infosys BPM.
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/abhishek0.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name text-theme-colored mt-0 mb-0">
                Mr.Abhishek Kumar
              </h2>
              <p style={{ fontSize: "18px", color: "green" }}>
                <b>Secretary</b>
              </p>{" "}
              <a href="#demo7" data-toggle="collapse">
                More Info
              </a>
              <div id="demo7" className="collapse">
                <br />
                <p style={{ textIndent: "50px", fontSize: "17px" }}>
                  A KVK alumnus, Abhishek Kumar is a master’s in finance and
                  marketing. His rich work experience across varied sectors like
                  Banking, Media and digital marketing makes him an analytical
                  thinker, a solution provider and someone who is keen to accept
                  challenges and deliver the desired results.
                  <br />
                  <br />
                  His affable nature makes him excellent at people management
                  skills and popular amongst all age groups
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <br />
          <br />

          <br />
          <div className="row">
            {/*--start-*/}
            {/*--start-*/}
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/rashmi.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name text-theme-colored mt-0 mb-0">
                Ms. Rashmi Chaudhary
              </h2>
              <p style={{ color: "green", fontSize: "18px" }}>
                <b>Treasurer</b>
              </p>{" "}
              <a href="#demo4" data-toggle="collapse">
                More Info
              </a>
              <div id="demo4" className="collapse">
                <br />
                <p style={{ textIndent: "50px", fontSize: "17px" }}>
                  A KVK Patna alumnus and a postgraduate in science from the
                  prestigious Science College PU, Rashmi Chaudhary started her
                  professional journey by teaching in the Physics department of
                  College of Basic Sciences in GBPant University of Agriculture
                  and Technology.
                  <br />
                  <br />
                  She believes that life is an everyday learning process, a
                  dreamer who dreams of a perfect harmonious social order. A
                  social activist dedicated to the Upliftment of the needy.
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/soni.jpeg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name mt-0 mb-0">Ms Kumari Soni</h2>
              <p style={{ color: "green", fontSize: "18px" }}>
                <b>Co-Ordinator(North)</b>
              </p>{" "}
              <a href="#demo2" data-toggle="collapse">
                More Info
              </a>
              <div id="demo2" className="collapse">
                <br />
                <p style={{ textIndent: "50px", fontSize: "17px" }}>
                  A successful business woman, Kumari Soni is M.com, M.Ed., a
                  law graduate and holds a doctorate degree on “Tax structure in
                  Bihar”. She firmly believes that when a woman is equipped with
                  right resources, she has the power to change entire
                  communities overcome poverty and social injustice. Women’s
                  welfare and their overall development is a cause she champions
                  and has been relentlessly working towards achieving it. In
                  this pursuit her special area of interest is women education,
                  health and economic growth.
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-2">
              <div className="thumb">
                <img src="images/about/ranju.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-2">
              <h2 className="name text-theme-colored mt-0 mb-0">
                Ms. Ranju Tripathy
              </h2>
              <p style={{ color: "green", fontSize: "18px" }}>
                <b>Co-Ordinator(South)</b>
              </p>{" "}
              <a href="#demo1" data-toggle="collapse">
                More info
              </a>
              <div id="demo1" className="collapse">
                <br />
                <p
                  style={{
                    textIndent: "50px",
                    fontSize: "16px",
                    padding: "10px",
                  }}
                >
                  Ranju Tripathy, the Managing Director of “Caffeno food and
                  hospitality Pvt ltd” is a successful blend of business acumen
                  and compassion. She is the founder member of '' HOUSE GROOM ''
                  a self-help group with a huge database of housemaids to
                  provide assistance to them and their family members in getting
                  their health check-up, preparing their Aadhar cards, free
                  accidental insurances, honing skills etc for improving the
                  quality of their lives. She is also one of the founder
                  directors of '' CRAFT GROOM '' which encourages the artists of
                  our country and preserves the form of arts which are on the
                  verge of extinction.
                </p>
                {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />

        <div className="row">
          {/*--start-*/}
          <div className="col-md-2">
            <div className="thumb">
              <img src="images/about/sharad.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-2">
            <h2 className="name mt-0 mb-0">Dr. Sharad</h2>
            <p style={{ fontSize: "16px", color: "green" }}>
              <b>Co-Ordinator(East)</b>
            </p>
            <a href="#demo8" data-toggle="collapse">
              More Info
            </a>
            <div id="demo8" className="collapse">
              <br />
              <p style={{ textIndent: "50px", fontSize: "16px" }}>
                A KVK Patna alumnus Sharad holds an MBBS degree from Rajiv
                Gandhi University of health and sciences, Bangalore and is a
                gold medallist in PG diploma in anaesthesiology and critical
                care from Patna Medical College, Patna.
                <br />
                <br />A doctor by profession, social activist by work and a
                philanthropist by nature, he is very compassionate and is
                working extensively towards upliftment of neglected and
                downtrodden in the society. He is associated with various
                charity works and social causes aimed at alleviating various
                social malaise and bridging the disparity in the society.
              </p>
              {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
          {/*--start-*/}
          <div className="col-md-2">
            <div className="thumb">
              <img src="images/about/ravi00.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-2">
            <h2 className="name text-theme-colored mt-0 mb-0">
              Mr. Ravi Ranjan
            </h2>
            <p style={{ fontSize: "16px", color: "green" }}>
              <b>Co-Ordinator(West)</b>
            </p>
            <a href="#demo5" data-toggle="collapse">
              More Info
            </a>
            <div id="demo5" className="collapse">
              <br />
              <p style={{ textIndent: "50px", fontSize: "16px" }}>
                A KVK Patna alumnus, Ravi Ranjan holds a master’s degree in
                business administration and further to it has attended multiple
                management development programs across the leading business
                schools including Indian Institute of Management. He is
                committed to identifying, exploring and implementing continuous
                improvements in projects. He is currently working with globally
                renowned leading healthcare organisation Roche as a senior
                manager Logistics and Operations. Known for his hard work and
                dedication, he has received various awards for collaboration,
                operational efficiency and GST Implementation .He has been
                relentlessly and successfully working to reduce last miles
                hassles in the procurement of medical equipments across states
                and hence latest to add to his accomplishments is the Game
                Changer Award and A Covid Warrior title.
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="thumb">
              <img src="images/bg/danish.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-2">
            <h2 className="name mt-0 mb-0">Mr. Danish Razavi</h2>
            <p style={{ fontSize: "16px", color: "green" }}>
              <b>Media Co-Ordinator</b>
            </p>
            <a href="#demo6" data-toggle="collapse">
              More Info
            </a>
            <div id="demo6" className="collapse">
              <br />
              <p style={{ textIndent: "50px", fontSize: "16px" }}>
                A KVK Patna alumnus, Danish Razavi holds an MBA degree with
                specialisation in marketing and HR. With an work experience of
                17 years in Operations, Sales and HR, he is highly focused and
                has a comprehensive understanding of Operations, procurement and
                BOT (Build, Operate and Transfer) model. He has been
                instrumental in setting up offices for reputed companies across
                the globe. At present he is heading the Ingram Micro cloud
                Indian operations, but overall he is an activist who is full of
                empathy and has been helping his countrymen by organising
                contributions, distributing food and arranging for the travel of
                stuck migrants back to their homes during the tough Covid times.
              </p>
              {/* <ul className="styled-icons icon-dark icon-theme-colored icon-sm mt-15 mb-0">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>

          <br />
        </div>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
