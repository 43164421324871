import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const Environment = () => {
  return (
    <div>
      <Header /><br/><br/>
      <img src="images/about/envir.jpg" alt="" className="youthImg mt-5" />

      <div>
        <div className="container pb-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 pb-sm-20">
                <h3 className="line-bottom mt-5">Environment</h3> <hr
                    style={{
                      marginTop: "-30px",
                      width: "6%",
                      float: "left",
                      border: "1px solid #bd1c2c",
                      height:'1.4px',
                      background:'#bd1626'
                    }}
                  />
                <p
                  style={{
                    fontFamily: "verdana",
                    color: "#333",
                    fontWeight: 600,
                    textIndent: "39px",
                    fontStyle: "bold",
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  <b>Stewart Lee Udal very rightly said </b>
                  <q>
                    <em>
                      Plans to protect air and water, wilderness and wildlife
                      are in fact plan to save the man
                    </em>
                  </q>
                  <br />
                </p>
                <p
                  style={{
                    fontFamily: "verdana",
                    color: "#333",
                    fontStyle: "bold",
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  Saving our planet, robust economic growth, and poverty
                  alleviation are one and thesame fight. All sections of society
                  need to be sensitized to protect and conserve our natural
                  wealth and resources. A flourishing ecosystem and human
                  welfare go hand in hand especially for the marginalized
                  section of the society.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Environment;
