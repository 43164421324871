import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const Covid19 = () => {
  return (
    <div>
      <Header />
      <img
        src="images/gallery/gallery13.jpeg"
        alt=""
        className="youthImg mt-5"
      />

      <div>
        <div className="container pb-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 pb-sm-20">
                <h3 className="line-bottom mt-5">Covid-19</h3>{" "}
                <hr
                  style={{
                    marginTop: "-30px",
                    width: "4%",
                    float: "left",
                    border: "1.2px solid #bd1c2c",
                  }}
                />
                <p
                  style={{
                    color: "#333",
                    textIndent: "39px",
                    textAlign: "justify",
                    fontSize: "18px",
                  }}
                >
                  <q>
                    <em style={{ fontWeight: 600 }}>
                      UDGAM DEVELOPMENT FOUNDATION plays a unique role in
                      educating the public about covid-19 as we have an
                      established access and trust within communities.
                    </em>
                  </q>
                  <br />
                  Our established presence within communities has helped us to
                  quickly provide required support in the rural areas. We are
                  using different combinations of social media, phone calls,
                  text messages, and pamphlets to reach out to affected people.
                  We are using our varied network to combat misinformation
                  regarding COVID-19.
                  <br />
                  We have been instrumental in helping people by providing
                  required medical support in person and through various other
                  mediums like telephonic and video consultancies. We are also
                  providing oxygen concentrators to the patients in Patna as of
                  now. We are in the process of distributing medical kits to the
                  affected people in rural areas of Bihar. Kits will contain
                  required medicines, masks, oximeter, thermometer, need-based
                  other materials along with printed guidelines. We always
                  advise people to consult Doctors before taking medicines.
                  <br />
                  We are planning to train volunteers to conduct home visits for
                  COVID-19 screening and education for people with disabilities.
                  We are also in the process of providing cooked food to COVID
                  patients. With the help of our patrons shortly, we will
                  provide ambulance services to the people in rural areas. We
                  are thankful to all our patrons and volunteers for their
                  continuous support. We are a dynamic team of professionals
                  striving to make a positive difference in the lives of our
                  COVID-affected people. We seek to strengthen affected people
                  to build resilient plans for the future. We invite
                  like-minded, enthusiastic, hardworking and passionate team
                  players to join us in this movement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Covid19;
