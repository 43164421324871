import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const YouthEmpowerment = () => {
  return (
    <div>
      <Header />
      <img src="images/about/yu0.jpg" alt="" className="youthImg mt-5" />

      <div>
        <div className="container pb-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 pb-sm-20">
                <br />
                <h3 className="line-bottom mt-0">Youth Empowerment</h3>
                <hr
                  style={{
                    marginTop: "-30px",
                    width: "6%",
                    float: "left",
                    border: "1px solid #bd1c2c",
                    height:'1.4px',
                    background:'#bd1626'
                  }}
                />
                <p
                  style={{
                    fontFamily: "verdana",
                    color: "#333",
                    textIndent: "49px",
                    fontStyle: "bold",
                    fontWeight: 600,
                    fontSize: "18px",
                  }}
                >
                  <q>
                    <em>
                      An educated and aware Youth is the hope of the nation.
                    </em>
                  </q>
                  <br />{" "}
                </p>
                <p
                  style={{
                    fontFamily: "verdana",
                    color: "#333",
                    fontStyle: "bold",
                    fontSize: "18px",
                  }}
                >
                  India has the highest youth population amongst all the
                  countries in the world. This Human Resource is the greatest
                  asset which if properly skilled can contribute to the overall
                  development of the Nation.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default YouthEmpowerment;
