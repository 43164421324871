import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const ContactUs = () => {
  return (
    <div>
      <Header />
      {/* <img
        src="images/bg/bg1.jpg"
        alt=""
        width="100%"
        height="auto"
        className="mt-5"
      /> */}
      <div class="container">
        <div class="innerwrap">
          <section class="section1 clearfix">
            <div class="textcenter">
              <span class="shtext">Contact Us</span>
              <span class="seperator"></span>
              <h1 style={{color:"rgb(189, 28, 44)",marginTop:'30px'}}>Drop Us a Mail</h1>
            </div>
          </section>

          <section class="section2 clearfix">
            <div class="col2 column1 first">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.0635966722143!2d77.22229481441708!3d28.6278559910449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd3396f872b3%3A0xb2f08db4f7054ed3!2sPrakash%20Deep%20RWA!5e0!3m2!1sen!2sin!4v1642961601186!5m2!1sen!2sin"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                className="maps"
              ></iframe>
            </div>
            <div class="col2 column2 last">
              <div class="sec2innercont">
                <div class="sec2addr">
                  <p>
                    GF-10, Prakashdeep Building, 7 Tolstoy Marg, New
                    Delhi-110001
                  </p>
                  <p>
                    <span class="collig">Phone :</span>9625077715
                  </p>
                  <p>
                    <span class="collig">Email :</span>{" "}
                    talktous@udgamdevelopmentfoundation.com
                  </p>
                 
                </div>
              </div>
              <div class="sec2contactform">
                <h3 class="sec2frmtitle">Want to Know More?? Drop Us a Mail</h3>
                <form action="">
                  <div class="clearfix">
                    <input
                      class="col2 first"
                      type="text"
                      placeholder="FirstName"
                    />
                    <input
                      class="col2 last"
                      type="text"
                      placeholder="LastName"
                    />
                  </div>
                  <div class="clearfix">
                    <input
                      class="col2 first"
                      type="Email"
                      placeholder="Email"
                    />
                    <input
                      class="col2 last"
                      type="text"
                      placeholder="Contact Number"
                    />
                  </div>
                  <div class="clearfix">
                    <textarea
                      name="textarea"
                      id=""
                      cols="30"
                      rows="7"
                      placeholder="Your message here...."
                    ></textarea>
                  </div>
                  <div class="clearfix">
                    <input type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
