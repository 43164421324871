import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const Education = () => {
  return (
    <div>
      <Header />
      <img src="images/about/edu.jpg" alt="" className="youthImg mt-5" />

      <div>
        <div className="container pb-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 pb-sm-20"><br/>
                <h3 className="line-bottom mt-0">Education</h3>  <hr
                  style={{
                    marginTop: "-30px",
                    width: "6%",
                    float: "left",
                    border: "1px solid #bd1c2c",
                    height:'1.4px',
                    background:'#bd1626'
                  }}
                />
                <p
                  style={{
                    fontFamily: "verdana",
                    fontWeight: 600,
                    color: "#333",
                    textIndent: "39px",
                    fontStyle: "bold",
                    fontSize: "18px",
                  }}
                >
                  “Education is the most powerful weapon which you can use to
                  change the world”
                  <em>
                     <small> Nelson Mandela</small>
                  </em>{" "}
                  <br />
                </p>
                <p
                  style={{
                    fontFamily: "verdana",
                    color: "#333",
                    fontStyle: "bold",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  Education empowers an individual not only by helping to earn a
                  livelihood but also by enhancing awareness about various
                  issues like available healthcare, one’s rights and duties.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Education;
