import React from "react";
import "./Carousel.css";
import { Link } from "react-router-dom";

const Carousel = () => {
  return (
    <div>
      <div id="demo" className="carousel slide" data-ride="carousel">
        {/* <ul className="carousel-indicators">
          <li data-target="#demo" data-slide-to={0} className="active" />
          <li data-target="#demo" data-slide-to={1} />
          <li data-target="#demo" data-slide-to={2} />
        </ul> */}
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="images/photos/udgam1.jpeg"
              alt="udgam"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner">Donate</h3>
              <p className="overlay-banner2">For the poor children</p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="images/photos/udgam2.jpeg"
              alt="udgam"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner3">For the poor children</h3>
              <p className="overlay-banner2">Raise Your helping hand</p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="images/photos/udgam4.jpeg"
              alt="photos"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner">Medical camp</h3>
              <p className="overlay-banner2">For a healthy living</p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="images/photos/udgam3.jpeg"
              alt="photos"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner">Women</h3>
              <p className="overlay-banner2">
                empowerment is society empowerment
              </p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="images/photos/udgam5.jpeg"
              alt="photos"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner">help us</h3>
              <p className="overlay-banner2">in saving the environment</p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src="images/bg/bg32.jpg"
              alt="photos"
              width={1100}
              height={500}
            />
            <div className="carousel-caption">
              <h3 className="overlay-banner">Youth</h3>
              <p className="overlay-banner2">are nation's future</p>
              <Link to="/donation">
                <button type="button" className="btn btn-md btn-danger">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#demo" data-slide="prev">
          <span className="carousel-control-prev-icon" />
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next">
          <span className="carousel-control-next-icon" />
        </a>
      </div>
    </div>
  );
};

export default Carousel;
