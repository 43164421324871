import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="navigation-wrap bg-light start-header start-style">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <nav class="navbar navbar-expand-md navbar-light">
                <a class="" href="home">
                  <img
                    src="/images/logo-wide.jpeg"
                    alt=""
                    width="120px"
                    height="70px;"
                  />
                </a>

                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav ml-auto py-4 py-md-0">
                    <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link class="nav-link" to="/home">
                        Home
                      </Link>
                    </li>
                    <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link class="nav-link" to="/about-us">
                        About Us
                      </Link>
                    </li>

                    <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <a
                        class="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Our Work<i class="fa fa-caret-down ml-2"></i>
                      </a>
                      <div class="dropdown-menu">
                        <Link class="dropdown-item" to="/health">
                          Health
                        </Link>
                        <div class="dropdown-menu">
                          <Link class="dropdown-item" to="/covid19">
                            COVID-19
                          </Link>
                        </div>
                        <Link class="dropdown-item" to="/education">
                          Education
                        </Link>
                        <Link class="dropdown-item" to="/environment">
                          Environment
                        </Link>
                        <Link class="dropdown-item" to="/women-empowerment">
                          Women Empowerment
                        </Link>
                        <Link class="dropdown-item" to="/youth-empowerment">
                          Youth Empowerment
                        </Link>
                      </div>
                    </li>
                    <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link class="nav-link" to="/gallery">
                        Gallery
                      </Link>
                    </li>
                    <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                      <Link class="nav-link" to="/contact-us">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
