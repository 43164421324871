import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";

const WomenEmpowerment = () => {
  return (
    <div>
      <Header />
      <img src="images/about/3839.jpg" alt="" className="youthImg mt-5" />

      <div>
        <div className="container pb-30">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 pb-sm-20">
                <h3 className="line-bottom mt-5">Women Empowerment</h3> <hr
                    style={{
                      marginTop: "-30px",
                      width: "6%",
                      float: "left",
                      border: "1px solid #bd1c2c",
                      height:'1.4px',
                      background:'#bd1626'
                    }}
                  />
                <p
                  style={{
                    color: "#333",
                    textIndent: "39px",
                    fontWeight: 600,
                    fontStyle: "bold",
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  An old African proverb{" "}
                  <q>
                    <em>
                      If you educate a man you educate an individual, but if you
                      educate a woman you educate a family (nation){" "}
                    </em>
                  </q>
                  <br />
                </p>
                <p
                  style={{
                    color: "#333",
                    fontStyle: "bold",
                    fontSize: "18px",
                    textAlign: "justify",
                  }}
                >
                  The education and empowerment of women would certainly lead to
                  a more caring, tolerant, just, and peaceful society. We talk
                  about gender equality but it can be achieved in the true sense
                  only when women of the world are empowered through education,
                  awareness, and financial independence. When a woman is
                  equipped with the right resources, she has the power to help
                  entire community to overcome poverty and social injustice.
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WomenEmpowerment;
