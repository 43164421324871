import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import YouthEmpowerment from "./Pages/YouthEmpowerment";
import Education from "./Pages/Education";
import Health from "./Pages/Health";
import Environment from "./Pages/Environment";
import WomenEmpowerment from "./Pages/WomenEmpowerment"
import Covid19 from "./Pages/Covid19";
import Gallery from "./Pages/Gallery";
import AboutUs from "./Pages/AboutUs";
import Donation from "./Pages/Donation";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/" component={Home} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/covid19" component={Covid19} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/donation" component={Donation} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/education" component={Education} />
          <Route exact path="/environment" component={Environment} />
          <Route exact path="/women-empowerment" component={WomenEmpowerment} />
          <Route exact path="/health" component={Health} />
          <Route exact path="/youth-empowerment" component={YouthEmpowerment} />
          <Route exact path="*" component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
