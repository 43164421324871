import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div>
      <footer className="section bg-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <h6 className="footer-heading text-uppercase text-white text-center">
                © Udgam Development Foundation All Rights Reserved 2021-22
              </h6>
              <ul className="list-unstyled text-center footer-link mt-4">
                <li>
                  <a href>Privacy Policy | Terms & Conditions</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4">
              <h6 className="footer-heading text-uppercase text-white text-center">
                Reach us
              </h6>
              <ul className="list-unstyled footer-link mt-4 text-center">
                <li>
                  <a href>+91 96250 77715 </a>
                </li>
                <li>
                  <a href>talktous@udgamdevelopmentfoundation.com</a>
                </li>
              </ul>
            </div>

            <div className="col-sm-4">
              <h6 className="footer-heading text-uppercase text-white text-center">
                Connect with us
              </h6>
              {/* <p className="contact-info mt-4 text-center">
                Contact us if need help withanything
              </p> */}
              {/* <p className="contact-info text-center">+01 123-456-7890</p> */}
              <div className="mt-5">
                <ul className="list-inline text-center">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/udgamdevelopmentfoundation"
                      target="_blank"
                    >
                      <i className="fab facebook footer-social-icon fa fa-facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.twitter.com/UdgamDevelopme1"
                      target="_blank"
                    >
                      <i className="fab twitter footer-social-icon fa fa-twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://instagram.com/udgamdevfoundation"
                      target="_blank"
                    >
                      <i className="fab google footer-social-icon fa fa-instagram" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://api.whatsapp.com/send?phone=919625077715"
                      target="_blank"
                    >
                      <i className="fab apple footer-social-icon fa fa-whatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
